<template>
  <div class="flex">
    <div class="py-4 pr-2 w-6/12">
      <div class="mb-4">
        <Persona />
      </div>
      <div>
        <Derecho />
      </div>
    </div>
    <div class="py-4 p-2 w-6/12">
      <div>
        <Soportes />
      </div>
      <div>
        <Respuestas />
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'VerDerechoPeticion',
  components: {
    Persona: defineAsyncComponent(
      () => import('./components/Persona.vue')
    ),
    Derecho: defineAsyncComponent(
      () => import('./components/DerechoInfo.vue')
    ),
    Soportes: defineAsyncComponent(
      () => import('./components/Soportes.vue')
    ),
    Respuestas: defineAsyncComponent(
      () => import('./components/Respuesta.vue')
    )
  },
  setup () {

  }
}
</script>
